import { PortableTextReactComponents } from '@portabletext/react';
import IKLink from '../shared/material/IKLink';
import Table from '../shared/table/Table';

const link = ({ value, children }: any) => {
  const noindex = value.noindex ? value.noindex : false;
  const blank = value.blank ? value.blank : false;
  const anchor = value.anchor
    ? value.anchor.includes('#')
      ? value?.anchor
      : `#${value.anchor}`
    : undefined;
  const url = anchor ? anchor : value?.internal ? value?.slug : value?.external;
  const category = value?.category ? value?.category : undefined;
  const link = {
    linkType: value?.linkType,
    url: value.linkType === 'ssu' ? value?.url : url,
    type: value?.internal ? ('internal' as const) : ('external' as const),
    callbackUrl: value?.callbackUrl,
    document: value?.document
      ? (value.document as
          | 'external'
          | 'page'
          | 'articles'
          | 'product'
          | 'category')
      : 'external',
    newWindow: blank,
    noIndex: noindex,
    category: category,
  };
  return (
    <>
      <IKLink link={link} rel={noindex ? 'noindex nofollow' : undefined}>
        {children}
      </IKLink>
    </>
  );
};

const highlight = ({ children, value }: any) => {
  return !value?.color || value?.color === 'default' ? (
    <span className='highlight'>{children}</span>
  ) : (
    <span
      className={`${!value?.color || value?.color === 'dark' ? 'text-black' : 'text-pink'} ${value?.weight === 'bold' && '!font-bold'} ${value?.weight === 'normal' && '!font-normal'} ${value?.size === 'small' && 'text-sm'}`}
    >
      {children}
    </span>
  );
};

const textColor = ({ children, value }: any) => {
  return (
    <span
      style={{
        color: value.value,
      }}
    >
      {children}
    </span>
  );
};
export const components: Partial<PortableTextReactComponents> = {
  block: {
    normal: ({ children }: any) => {
      return <>{children}</>;
    },
    hardBreak: () => {
      return <br />;
    },
    small: ({ children }: any) => {
      return (
        <span>
          <small>{children}</small>
        </span>
      );
    },
  },
  marks: {
    link: link,
    highlight: highlight,
    textColor,
  },
};

export const noParagraphs: Partial<PortableTextReactComponents> = {
  block: {
    normal: ({ children }: any) => {
      return <>{children}</>;
    },
    hardBreak: () => {
      return <br />;
    },
  },
  marks: {
    // Add custom render for list
    link: link,
    highlight: highlight,
    textColor,
  },
};

export const paragraphs: Partial<PortableTextReactComponents> = {
  block: {
    small: ({ children }: any) => {
      return (
        <span>
          <small>{children}</small>
        </span>
      );
    },
  },
  list: ({ children, type }: any) => {
    if (type === 'bullet') {
      return <ul className='list-outside list-disc pl-6'>{children}</ul>;
    }
    return <ol className='list-outside list-decimal pl-6'>{children}</ol>;
  },
  marks: {
    link: link,
    highlight: highlight,
    textColor,
  },
};

export const paragraphsWithTables: Partial<PortableTextReactComponents> = {
  block: {
    small: ({ children }: any) => {
      return (
        <span>
          <small>{children}</small>
        </span>
      );
    },
  },
  marks: {
    link: link,
    highlight: highlight,
  },
  types: {
    table: ({ value, children }: any) => {
      // @ts-ignore
      return <Table node={value}>{children}</Table>;
    },
  },
};

'use client';
import { Button, ButtonProps } from '@material-tailwind/react';
import { variant } from '@material-tailwind/react/types/components/button';
import { MenuItemLink } from '@/types/types';
import IKLink from './IKLink';
import { MouseEvent, useCallback, useState } from 'react';
import useCart from '@/hooks/useCart';
import { isCartSlideOutOpenAtom } from '@/store/cartStore';
import { useAtom } from 'jotai';
import { CircleNotch } from '@phosphor-icons/react';
import { mixpanelBtnClick } from '@/lib/mixpanel/services/client';
import { toastAddToCartAtom } from '@/store/toastsStore';
import { useStatsigClient } from '@statsig/react-bindings';
import { cn } from '@/components/utils/utils';

interface Props extends Omit<ButtonProps, 'color' | 'variant'> {
  color?:
    | 'charcoal'
    | 'gray'
    | 'green'
    | 'yellow'
    | 'orange'
    | 'pink'
    | 'white'
    | 'teal'
    | 'light-blue'
    | 'transparent';
  variant?: variant | 'outlined';
  link?: MenuItemLink;
  linkProps?: {
    className?: string;
  };
  cardTitle?: string;
  onMouseEnter?: (
    e: MouseEvent<HTMLButtonElement>
  ) => void | ((e: React.MouseEvent<HTMLButtonElement>) => void);
  onClick?: (
    e: MouseEvent<HTMLButtonElement>
  ) => void | ((e: React.MouseEvent<HTMLButtonElement>) => void);
}

export default function IKButton(props: Props) {
  const {
    color,
    link,
    className,
    linkProps,
    cardTitle,
    onClick,
    ...restProps
  } = props;
  const { addItem, addItems } = useCart();
  const [, setOpen] = useAtom(isCartSlideOutOpenAtom);
  const [isLoading, setLoading] = useState(false);
  const [toastAddtoCart, setToastAddToCart] = useAtom(toastAddToCartAtom);
  const { logEvent } = useStatsigClient();

  const addToCartHandler = useCallback(async () => {
    if (!link?.products && !link?.variantGid === undefined && !isLoading)
      return;

    setLoading(true);

    // Disable scrolling while adding to cart
    document.body.style.overflow = 'hidden';

    try {
      /**
       * For existing links that have a variantGid, add the item to the cart
       */
      if (link?.variantGid && !link?.products?.length) {
        await addItem(
          {
            variantId: link?.variantGid,
            quantity: 1,
          },
          link?.clearExistingItemsFirst
        );
      }
      /**
       * For new links that have multiple products, add all the items to the cart
       */
      if (link?.products && link?.products?.length === 1) {
        await addItem(
          {
            variantId: link?.products[0].variantGid,
            quantity: 1,
          },
          link?.clearExistingItemsFirst
        );
      } else if (link?.products && link?.products?.length > 1) {
        await addItems(
          link?.products.map((product) => ({
            variantId: product.variantGid,
            quantity: 1,
          })),
          link?.clearExistingItemsFirst
        );
      }

      setOpen(true);
    } finally {
      setLoading(false);
      document.body.style.overflow = '';
    }
  }, [link?.variantGid, addItem, setOpen]);

  const buttonColor =
    color === 'teal' ? 'light-blue' : color === 'charcoal' ? 'gray' : color;

  const onButtonClick = (btn: HTMLButtonElement, cardTitle: string) => {
    mixpanelBtnClick(btn, cardTitle);
    logEvent(
      'button_click',
      btn?.innerText ?? btn?.getAttribute('aria-label'),
      {
        page_url: window.location.origin + window.location.pathname,
        page_title: document.title,
        card_title: cardTitle,
        //@ts-ignore
        button_url: btn.href?.replace('?hsLang=en', ''),
      }
    );
  };

  const handleButtonClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      onButtonClick(e.currentTarget, cardTitle ?? 'No card title');
      if (onClick) {
        onClick(e);
      }
    },
    [onClick, cardTitle]
  );

  const handleSetLocalStorageFlag = useCallback(() => {
    if (link?.addToCart && toastAddtoCart === null) {
      setToastAddToCart(new Date());
    }
  }, [link?.addToCart, toastAddtoCart, setToastAddToCart]);

  return (
    <>
      {(link?.url && link?.type) || (link?.products && link.type) ? (
        <>
          {link.addToCart ? (
            <Button
              onClick={() => {
                addToCartHandler();
                handleSetLocalStorageFlag();
              }}
              className={cn(
                className,
                isLoading &&
                  'pointer-events-none cursor-not-allowed select-none'
              )}
              // @ts-ignore
              color={buttonColor}
              {...restProps}
            >
              {isLoading ? (
                <CircleNotch
                  weight='bold'
                  className='mx-auto h-[28px] w-6 animate-spin'
                />
              ) : (
                restProps.children
              )}
            </Button>
          ) : (
            <IKLink
              // @ts-ignore
              onClick={onClick}
              className={`flex flex-shrink-0 text-center ${linkProps?.className}`}
              link={link}
            >
              <Button
                className={`${className}`}
                // @ts-ignore
                color={buttonColor}
                {...restProps}
              />
            </IKLink>
          )}
        </>
      ) : (
        <Button
          className={`${className}`}
          // @ts-ignore
          color={buttonColor}
          onClick={handleButtonClick}
          {...restProps}
        />
      )}
    </>
  );
}
